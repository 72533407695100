import React from 'react'
import styled from 'styled-components'
import Animate from '../animate'

const LinksContainer = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    justify-content: space-between;
    margin: 5vmax 0 3vmax;
  `};
`

const LinkItem = styled.div`
  :not(:last-child) {
    margin-right: 3vw;
  }
  ${p => p.css};
  animation-name: ${p => p.theme.animations.listItems};
  font-size: 0.9vw;
  text-transform: uppercase;
  cursor: pointer;
  color: ${p => p.theme.text.black[200]};
  :hover {
    color: ${p => p.theme.palette.secondary};
  }
  transition: color 200ms ease-in-out;
  ${props => props.theme.media.sm`
    font-size: 1rem;
  `};
`

const SubNav = ({ location, animate }) => {
  const scrollInView = i => {
    document.getElementById(i).scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    if (location.state && typeof location.state.i !== 'undefined') {
      setTimeout(() => {
        scrollInView(location.state.i)
      }, animate && 1000)
    }
  }, [location.state && location.state.i, animate])

  return (
    <LinksContainer>
      {['blockchain', 'product', 'web', 'mobile'].map((link, i) => (
        <Animate delay={`${200 + i * 100}ms`} key={link} animate={animate}>
          {props => (
            <LinkItem onClick={() => scrollInView(i)} {...props}>
              {link}
            </LinkItem>
          )}
        </Animate>
      ))}
    </LinksContainer>
  )
}

export default SubNav
