import React from 'react'
import Container from '../components/container'
import LandingMapAndContact from '../components/landing/landing-map-contact'
import LandingQuestionsAndProjects from '../components/landing/landing-questions-projects'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'
import HowWeWork from '../components/services/how-we-work'
import ServiceItem from '../components/services/services-item'
import SubNav from '../components/services/sub-nav'
import useBreakpoints from '../hooks/use-breakpoints'
import useServices from '../hooks/use-services'

const ServicesPage = ({ animate, location }) => {
  const { services, getImage } = useServices()
  const { xs, sm } = useBreakpoints()

  return (
    <>
      <SEO
        title="Services"
        description="Check out our services - Product development, Blockchain development, Web development and Mobile development"
      />
      <PageTitle title="Services" animate={animate}>
        {!sm && !xs && <SubNav location={location} animate={animate} />}
      </PageTitle>
      <Container width="80vw" mb="6vw">
        {(sm || xs) && <SubNav location={location} animate={animate} />}
        {services.map((service, i) => (
          <ServiceItem
            key={i}
            i={i}
            id={i}
            service={service}
            getImage={getImage}
            isFirst={i === 0}
            isLast={i === services.length - 1}
          />
        ))}
      </Container>
      <Container width="67vw" mb="6vw">
        <HowWeWork />
      </Container>
      <LandingQuestionsAndProjects question={false} />
      <LandingMapAndContact />
    </>
  )
}

export default ServicesPage
