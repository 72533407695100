import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import ServicesExtra from './services-extra'

const ServiceItem = styled.div`
  margin-top: 5vw;
`

const ServiceImageWrapper = styled.div`
  position: relative;
  :before {
    content: ${props => `'${props.content}'`};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.palette.secondary};
    height: 6vw;
    width: 6vw;
    font-size: 1.2vw;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
    border: 1px solid currentColor;
    top: calc(50% - 3vw);
  }
  :after {
    content: '';
    position: absolute;
    height: ${props => (props.isFirst || props.isLast ? '50%' : '100%')};
    border-left: 1px solid ${props => props.theme.palette.secondary};
    left: 3vw;
    ${props => (props.isLast ? 'top: 0' : 'bottom: 0')}
  }
  ${props => props.theme.media.sm`
    &:before,&:after {
      display: none;
    }
  `};
`

const ServiceImage = styled(GatsbyImage)`
  width: 50%;
  ${props => props.theme.media.sm`
    width: 100%;
  `};
  margin: auto;
`

const ServiceBodyWrapper = styled.div`
  position: relative;
  :before {
    content: '';
    position: absolute;
    height: calc(100% + 7vw);
    top: -2vw;
    ${props =>
      !props.isLast &&
      'border-left: 1px solid ' + props.theme.palette.secondary};
    left: 3vw;
  }
  ${props => props.theme.media.sm`
    &:before {
      display: none;
    }
  `};
`

const ServiceBody = styled.div`
  text-align: center;
  width: 70%;
  ${props => props.theme.media.sm`
    width: 100%;
  `};
  margin: auto;
`

const ServiceTitle = styled.h1`
  font-weight: bold;
  font-size: 3.4rem;
  ${props => props.theme.media.xs`
   font-size: 2.7rem;
  `};
  margin: 2vmax 0;
`

const ServiceText = styled.div`
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: ${p => p.theme.text.black[200]};
  ${props => props.theme.media.xs`
   font-size: 0.95rem;
  `};
`

const Services = ({ service, getImage, i, ...indexes }) => {
  return (
    <ServiceItem>
      <ServiceImageWrapper {...indexes} content={`0${i + 1}`}>
        <ServiceImage
          alt={service.title}
          title={service.title}
          fluid={getImage(i)}
        />
      </ServiceImageWrapper>
      <ServiceBodyWrapper {...indexes}>
        <ServiceBody>
          <ServiceTitle>{service.title}</ServiceTitle>
          <ServiceText>{service.texts.long}</ServiceText>
          {service.extra && <ServicesExtra extra={service.extra} />}
        </ServiceBody>
      </ServiceBodyWrapper>
    </ServiceItem>
  )
}

export default Services
