import { graphql, useStaticQuery } from 'gatsby'

export default function useServices() {
  const { site, img } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            services {
              title
              texts {
                short
                long
              }
              extra {
                title
                data {
                  key
                  value
                }
              }
            }
          }
        }
        img: allImageSharp(
          filter: { fluid: { src: { regex: "/service/" } } }
          sort: { order: ASC, fields: sizes___originalName }
        ) {
          edges {
            node {
              fluid(maxWidth: 3000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )
  const services = site.siteMetadata.services
  const images = img.edges

  return {
    services,
    images,
    getImage: i => images[i].node.fluid,
  }
}
