import React from 'react'
import styled from 'styled-components'
import ContactForms from '../../forms/contact-forms'
import FullScreenModal from '../full-screen-modal/full-screen-modal'
import LandingCtaBox from './landing-cta-box'

const Wrapper = styled.section`
  height: 35vmax;
  display: flex;
  color: white;
  ${props => props.theme.media.sm`
    flex-direction: column;
    height: ${p => (p.question && p.project ? 60 : 30)}vmax;
  `};
`

const LandingQuestionsAndProjects = ({ question = true, project = true }) => (
  <Wrapper question={question} project={project}>
    {question && (
      <FullScreenModal
        withHeader
        color="primary"
        trigger={
          <LandingCtaBox
            color="primary"
            text="QUESTION"
            hoverText="I HAVE A QUESTION"
            sideText="If you have any questions, write to us"
            sideTextOrientation="vertical"
          />
        }
      >
        <ContactForms formName="question" />
      </FullScreenModal>
    )}
    {project && (
      <FullScreenModal
        withHeader
        color="secondary"
        trigger={
          <LandingCtaBox
            color="secondary"
            text="PROJECT"
            hoverText="START A PROJECT"
            sideText="If you have a project, write to us"
            sideTextOrientation="horizontal"
          />
        }
      >
        <ContactForms formName="project" />
      </FullScreenModal>
    )}
  </Wrapper>
)

export default LandingQuestionsAndProjects
