import React from 'react'

const PriceIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" {...props}>
    <path
      fill="currentColor"
      d="M551.4 636.9c-8 8.2-19.3 13.6-34 16.3V675h-27.8v-20.9c-9.9-.5-19.3-2-28.3-4.5s-16.5-5.6-22.4-9.3l15-33.9c6.6 3.8 13.9 6.9 22.1 9.2 8.2 2.3 16 3.5 23.5 3.5 6.4 0 11-.6 13.8-1.9 2.8-1.2 4.1-3.1 4.1-5.6 0-2.9-1.8-5.1-5.4-6.5-3.6-1.4-9.6-3-17.9-4.7-10.7-2.2-19.5-4.6-26.6-7.2-7.1-2.6-13.3-6.8-18.5-12.6-5.3-5.9-7.9-13.8-7.9-23.8 0-11.6 4.2-21.5 12.5-29.8 8.4-8.3 20.4-13.5 36-15.8v-21.5h27.8v21.1c15.8 1.4 29.1 5.3 40.1 11.4l-14 33.7c-13.5-6.8-26.4-10.3-38.5-10.3-12 0-18 2.9-18 8.7 0 2.8 1.8 4.8 5.3 6.2 3.6 1.4 9.4 2.9 17.6 4.4 10.5 2 19.4 4.2 26.6 6.8 7.2 2.6 13.5 6.7 18.8 12.5 5.3 5.8 8 13.7 8 23.7 0 11.1-3.9 20.7-11.9 29z"
    />
    <path
      fill="currentColor"
      d="M665.6 417c-8.2-8.2-17-15.8-26.2-22.6v-84.8c0-76.8-62.5-139.3-139.3-139.3s-139.3 62.5-139.3 139.3v84.7c-20.2 15-38.2 33.3-53 54.4-1.5 1.9-2.9 4-4.1 6.1-24.8 38-38 82.2-38 127.7 0 129.1 105.1 234.2 234.2 234.2s234.2-105.1 234.2-234.2c.1-62.4-24.3-121.2-68.5-165.5zM419.1 296.5c0-40.6 36.8-73.6 82-73.6s82 33 82 73.6l.2 68c-26.5-6.2-45.2-11.1-82.3-11.1-35.9 0-56.7 7.4-83.2 13.6l1.3-70.5zM500 759.2c-100 0-180.7-79.2-181.3-176.6-.6-96.8 80.6-176 181.3-176.6 100-.7 181.3 79.2 181.3 176.6S600 759.2 500 759.2z"
    />
  </svg>
)

export default PriceIcon
