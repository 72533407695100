import React from 'react'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `};
`

const Title = styled.div`
  margin: 2.5vmax auto 2.5vmax;
  text-transform: uppercase;
  width: 60%;
  ${props => props.theme.media.sm`
    width: 95%;
    font-size: 1rem;
    white-space: normal;
  `};
  font-size: 1.15rem;
  line-height: 1.4;
  font-weight: bold;
  white-space: pre-wrap;
`

const ArrayItem = styled.div`
  flex: 1;
  border: 1px solid ${p => p.theme.border.line};
  padding: 2em 0.3em;
  :not(:first-child) {
    border-left: none;
    ${props => props.theme.media.sm`
      border-left: 1px solid ${p => p.theme.border.line};
      border-top: none;
  `};
  }
`

const Text = styled.div`
  font-weight: normal;
  font-size: 0.95rem;
  color: ${p => p.theme.text.black[200]};
  line-height: 1.7;
`

const Key = styled.div`
  flex: none;
  width: 210px;
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  white-space: pre-line;
  ${props => props.theme.media.sm`
     white-space: normal;
     margin-bottom: 1rem;
     width: 100%;
     text-align: center;
  `};
`

const Value = styled(Text)`
  flex: 1;
  text-align: left;
  margin-bottom: 3vmax;
  font-size: 1rem;
`

const AsTable = ({ data }) => {
  return (
    <Flex>
      {data.map(({ key }) => (
        <ArrayItem key={key}>
          <Text>{key}</Text>
        </ArrayItem>
      ))}
    </Flex>
  )
}

const AsList = ({ data }) => {
  return data.map(({ key, value }) => (
    <Flex key={key}>
      <Key key={key}>{key}</Key>
      <Value>{value}</Value>
    </Flex>
  ))
}

const ServicesExtra = ({ extra: { title, data } }) => {
  return (
    <div>
      {title && <Title>{title}</Title>}
      {!data[0].value ? <AsTable data={data} /> : <AsList data={data} />}
    </div>
  )
}

export default ServicesExtra
