import React from 'react'
import styled from 'styled-components'
import PriceIcon from '../../images/price'
import StaffIcon from '../../images/staff'

const Title = styled.h1`
  text-transform: uppercase;
  font-size: 3.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2vmax;
  ${props => props.theme.media.sm`
    margin-bottom: 3rem;
    font-size: 2.7rem;
`};
`

const ListItem = styled.div`
  display: flex;
  ${props => props.theme.media.sm`
    flex-direction: column;
  `};
  &:not(:last-child) {
    margin-bottom: 3vmax;
  }
`

const Icon = styled.div`
  display: flex;
  height: 5rem;
  width: 5rem;
  border-radius: 27%;
  background: ${p => p.theme.gradients.primary.main};
  color: white;
  svg {
    margin: auto;
  }
`

const Left = styled.div`
  white-space: pre-line;
  font-weight: bold;
  font-size: 1.4rem;
  display: flex;
  align-items: baseline;
  text-transform: uppercase;
  flex-basis: 27.5%;
  ${Icon} {
    margin-right: 1.2rem;
  }
  > div {
    display: flex;
    align-items: center;
    ${props => props.theme.media.sm`
      margin-bottom: 1.2rem;
    `};
  }
`

const Right = styled.div`
  flex: 1;
  color: ${p => p.theme.text.black[200]};
  font-size: 1.1rem;
  line-height: 1.9rem;
`

const HowWeWork = () => {
  return (
    <>
      <Title>How We Work</Title>
      <ListItem>
        <Left>
          <div>
            <Icon>
              <PriceIcon height={50} width={50} />
            </Icon>
            {`Fixed\nprice projects`}
          </div>
        </Left>
        <Right>
          We can help you bring your digital ideas to life by building
          exceptional products from scratch. If you already know what kind of
          software you want and can specify it in detail, we can give you a
          quote and stick to it, so you have a precise idea of the costs in
          advance. We can help you pre-plan the project, write the
          specifications and architecture, create wireframes and finally build
          and maintain software that gives you a unique digital edge. This model
          is ideal for projects where there is little to no changes expected in
          terms of features and the overall scope of the project.
        </Right>
      </ListItem>
      <ListItem>
        <Left>
          <div>
            <Icon>
              <StaffIcon height={45} width={45} />
            </Icon>
            {`Time\nand material`}
          </div>
        </Left>
        <Right>
          We can also provide you with a Dedicated Team which will be involved
          full-time in your project and 100% tailored to your requirements ,
          whether you are starting your new Start Up, developing an online
          business or simply looking to expand your existing team. This model is
          preferable if you don't have a detailed idea of how the software
          should work or you just want to have the flexibility to change your
          requirements as we go.
        </Right>
      </ListItem>
    </>
  )
}

export default HowWeWork
