import React from 'react'

const StaffIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 1000 1000" xmlSpace="preserve" {...props}>
    <path
      fill="currentColor"
      d="M525.6 531.9c-7.5-.8-15.4-1.3-23.5-1.5h-3c-94.5 1.3-144.6 54-170.1 98-26.7 46.2-31.6 92.2-31.8 94.2-.8 7.6 1.5 15.1 6.4 21 4.9 6 12 9.8 19.8 10.6 1 .1 2 .1 2.9.1 15 0 27.5-11.1 29-25.9 0-.4 4.4-37.1 24.9-72.2 15.5-26.4 35.7-45.2 60.5-56.2 19.5-25.8 46.8-51.3 84.9-68.1zM501.8 487c13.8 0 27.1-2.3 39.4-6.6-20.9-12.8-37.9-31.2-49.2-53.1-30.1-4.6-53.3-30-53.3-60.6s23.1-56.1 53.3-60.6c2.1-4.1 4.4-8.2 7-12.1.7-1.2 1.5-2.4 2.3-3.5 10.7-15.4 24.4-28 39.9-37.5-12.5-4.4-25.8-6.6-39.5-6.6-39.3 0-76.2 19.3-98.7 51.6-.8 1-1.5 2-2.1 3.1-12.8 19.5-19.5 42.2-19.5 65.6.1 66.4 54.1 120.3 120.4 120.3zM385.6 531.9c-7.5-.8-15.4-1.3-23.5-1.5h-3c-94.5 1.3-144.6 54-170.1 98-26.7 46.2-31.6 92.2-31.8 94.2-.8 7.6 1.5 15.1 6.4 21 4.9 6 12 9.8 19.8 10.6 1 .1 2 .1 2.9.1 15 0 27.5-11.1 29-25.9 0-.4 4.4-37.1 24.9-72.2 15.5-26.4 35.7-45.2 60.5-56.2 19.5-25.8 46.8-51.3 84.9-68.1z"
    />
    <path
      fill="currentColor"
      d="M361.8 487c13.8 0 27.1-2.3 39.4-6.6-20.9-12.8-37.9-31.2-49.2-53.1-30.1-4.6-53.3-30-53.3-60.6s23.1-56.1 53.3-60.6c2.1-4.1 4.4-8.2 7-12.1.7-1.2 1.5-2.4 2.3-3.5 10.7-15.4 24.4-28 39.9-37.5-12.5-4.4-25.8-6.6-39.5-6.6-39.3 0-76.2 19.3-98.7 51.6-.8 1-1.5 2-2.1 3.1-12.8 19.5-19.5 42.2-19.5 65.6.1 66.4 54.1 120.3 120.4 120.3z"
    />
    <g>
      <path
        fill="currentColor"
        d="M842.9 722.6c-.2-1.9-5.1-47.9-31.8-94.2-25.4-44-75.6-96.7-170.1-98h-2.9c-94.5 1.3-144.6 54-170.1 98-26.7 46.2-31.6 92.2-31.8 94.2-.8 7.6 1.5 15.1 6.4 21 4.9 6 12 9.8 19.8 10.6 1 .1 2 .1 2.9.1 15 0 27.5-11.1 29-25.9 0-.4 4.4-37.1 24.9-72.2 26.5-45.2 67-68.1 120.3-68.1 50.5 0 94.4 24.9 120.4 68.2 20.6 34.3 24.7 70.5 24.9 72 1.5 14.8 14 26 29 26 .9 0 1.9 0 2.9-.1 7.8-.8 14.9-4.5 19.8-10.6 4.9-5.9 7.2-13.4 6.4-21zM640.8 487c66.4 0 120.3-54 120.3-120.3 0-32.1-12.5-62.3-35.3-85.1-22.8-22.8-53-35.3-85.1-35.3-39.3 0-76.2 19.3-98.7 51.6-.8 1-1.5 2-2.1 3.1-12.8 19.5-19.5 42.2-19.5 65.6.1 66.5 54.1 120.4 120.4 120.4zm0-181.7c34.7 0 63 27.5 63 61.4s-28.3 61.4-63 61.4-63-27.5-63-61.4 28.3-61.4 63-61.4z"
      />
    </g>
  </svg>
)

export default StaffIcon
